import React, { useEffect, useState } from 'react'
import { Table, Spin, message } from 'antd'
import Image from 'next/image';
import { InputCustom, SearchCustom } from '../MainCode/MainForm'
import StorageApi from '../APIConfig/MainApi';
import { Excel } from "antd-table-saveas-excel";

function DataTableSearchNew(props) {
  let { columns, dataSource, loading, colorText, Tfooter, paginate, fetchData } = props;
  const [total_data, setTotalData] = useState('')
  const [paging, setPagination] = useState('')
  const [heading, setHeading] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState(dataSource);
  const [isCaseSensitive, setIsCaseSensitive] = useState(false);
  useEffect(() => {
    FilteringTable(columns, dataSource);
    setTotalData(paginate?.total);
    handleSearch(searchText);
  }, [columns, dataSource, filteredData, paginate, isCaseSensitive, searchText]);

  const FilteringTable = (field=[], data=[]) => {
    let myfield =[
      {title: 'No',dataIndex: 'no',width: '5%',align: 'center',sorter: (a, b) => a.no - b.no}
    ]
    for (let i = 0; i < field.length; i++) {
      if (field[i].filterSearch == undefined) {
        if (field[i].dataIndex == 'action') {
          myfield.push(
            { title: 'Action', 
              dataIndex: 'action',  
              align: 'center', 
              fixed: "right", 
              width : '13%'
            }
          )          
        }else{
          myfield.push(field[i])
        }
      }else{
        const indexData = field[i].dataIndex;
        const filtering = data.filter(
          (element, index) => (
            data.findIndex((obj) => obj[indexData] === element[indexData]) === index
          )
        );
        myfield.push(
          {
            title : field[i].title,
            dataIndex:field[i].dataIndex,
            filters: filtering.map(item => {
              return ({ text: item[indexData], value: item[indexData] })
            }),
            align : field[i].align != undefined ? field[i].align : 'left',
            filterSearch: true,
            onFilter: (filters, record) => record[indexData] && record[indexData].indexOf(filters) === 0,
          })
      }
    }
    setHeading(myfield)
  }

  const handleSearch = (value) => {
    if (!value) {
      setFilteredData(dataSource);
      return;
    }
    const filtered = dataSource.filter(item =>
      Object.values(item).some(val => {
        const itemValue = String(val);
        const searchValue = isCaseSensitive ? value : value.toLowerCase();
        return isCaseSensitive 
          ? itemValue.includes(searchValue) 
          : itemValue.toLowerCase().includes(searchValue);
      })
    );
    setFilteredData(filtered);
    
  };

  const handleChange = (pagination, filters, sorter, extra) => {
        const {current, pageSize} = pagination
        const updatedPagination = {
          page: current,
          perPage: pageSize,
          keyWord: searchText
        };
        // Call AllData with updated pagination
        fetchData(updatedPagination);
        return updatedPagination;
  }

  return (
    <>
      <div className='flex items-start gap-4'>
        <div className='flex flex-col items-center gap-2'>
          <InputCustom type='number' width='w-[80px]' onChange={e => {setPagination(e.target.value), fetchData({page: paginate.page, perPage: e.target.value, keyWord: paginate.keyWord})}} />
          <span className={`${colorText} text-[12px] font-light`}>
            <b>{paging || paginate.perPage} rows/Page</b>
          </span>
        </div>
        <input
          type='text'
          placeholder='Search...'
          value={searchText}
          onChange={(e) => {setSearchText(e.target.value), fetchData({page: paginate.page, perPage: paginate.perPage, keyWord: e.target.value})}}
          className='p-2 border border-gray-300 rounded bg-white h-9 w-[200px]'
        />
        <label className="flex items-center gap-2 text-sm">
            <input 
              type="checkbox" 
              checked={isCaseSensitive} 
              onChange={() => setIsCaseSensitive(!isCaseSensitive)}
            />
            <span className={colorText}>Case-sensitive search</span>
          </label>
      </div>
      <div className="overflow-x-auto mt-3">
        <Table
          columns={heading}
          dataSource={filteredData}
          loading={loading}
          onChange={handleChange}
          size={'large'}
          footer={(value) => <div className='flex flex-col'>
            <p className='text-black'>
              Total record data : <b> {total_data}</b>
            </p>
          </div>}
          pagination={
            paginate ? {  
            current: paginate.page,
            pageSize: paginate.perPage,
            total: paginate.total,
            position: ['bottomRight'] } : false}
          scroll={{ x: props.Tscroll ? props.Tscroll : '100vw' }}
          tableLayout={{ width: "100vw" }}
          bordered
        />
      </div>
    </>
  );
}

export default DataTableSearchNew
